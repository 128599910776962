ul.list-benefits-horizontal {
	list-style: none;
	display: flex;
	width: 100%;
	height: 70px;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	li {
		font-weight: 600;
		font-size: $font-size-md;
		&:before {
			font-family: FontAwesome;
			content: "\f058";
			font-size: 20px;
			color: $green;
			margin-right: 10px;
		}
	}
}
ul.list-benefits-vertical {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		font-weight: 400;
		margin-bottom: 10px;
		font-size: $font-size-md;
		&:before {
			font-family: FontAwesome;
			content: "\f058";
			font-size: 20px;
			color: $green;
			margin-right: 10px;
		}
	}
}
ul.list-contact {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		font-size: $font-size-md;
		margin-bottom: 1rem;

		&:before {
			font-family: FontAwesome;
			color: $white;
			background-color: $secondary;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			display: inline-flex;
			margin-right: 5px;
			align-items: center;
			justify-content: center;
		}
		&.phone:before {
			content: "\f095";
			font-size: 15px;
			padding-top: 3px;
		}
		&.mail:before {
			content: "\f0e0";
			font-size: 12px;
		}
		span {
			display: block;
			font-size: $font-size-sm;
			i {
				margin-right: 6px;
				font-size: 10px;
				color: $green;
				&.facebook {
					color: $white;
					font-size: 14px;
				}
				&.red {
					color: $red;
				}
			}
		}
	}
}

.list-right-align {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: flex;
		justify-content: space-between;
	}
}

.list-chevron-green {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		&:before {
			font-family: FontAwesome;
			content: "\f054";
			color: $green;
			margin-right: 7px;
		}
	}
}
.list-chevron {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		&:before {
			font-family: FontAwesome;
			content: "\f054";
			color: $secondary;
			margin-right: 7px;
		}
	}
}
main.content {
	article {
		ul {
			margin: 0;
			margin-bottom: 30px;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 5px;
				color: $primary;
				&:before {
					font-family: FontAwesome;
					content: "\f054";
					color: $secondary;
					margin-right: 7px;
				}
			}
			&.no-arrows {
				list-style-type: disc;
				margin-left: 20px;
				li {
					color: $dark-blue;
					&:before {
						display: none;
					}
				}
			}

			&.list-unstyled {
				list-style-type: none;
				li {
					color: $dark-blue;
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
