.modal {
  margin-top: 57px;

  .btn-close {
    background: url(img/close.svg);
    background-repeat: no-repeat;
    margin-top: 0px;
  }
}

.modal {
  &.returning-visitor {
    margin-top: 0;
    padding-right: 0 !important;
    .modal-title {
      width: 100%;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: $font-size-md;
      }
    }
    .modal-dialog {
      margin: auto;
      top: 20%;
    }
    .modal-footer {
      justify-content: center;
    }
    .btn-close {
      align-self: flex-start;
    }
  }
}
