dot-form {
  max-width: 500px;
  .dot-label {
    display: block;
    span {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      font-size: 1.13rem;
      color: #043d7a;
      font-weight: 600;
    }
    input, textarea {
      display: block;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      appearance: none;
      background-color: var(--bs-body-bg);
      background-clip: padding-box;
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      padding: 10px 15px;
      color: #6a7b8d;
      border-color: #cfd5dc;
      font-weight: 400;
    }
  }
}
