.card {
  border-radius: $border-radius-sm;
  margin: 0;
  background: none;
  border: none;
  @include media-breakpoint-up(sm) {
    padding: 20px 25px;
  }
  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  .card-body {
    padding: 22px;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .card-image {
    margin-bottom: 1rem;
  }
  &.white {
    background-color: $white;
    h3,
    h2,
    h4 {
      color: $primary;
    }
    h2 {
      font-weight: 600;
    }
  }
  &.primary {
    background-color: $primary;
    color: $white;
    a.link-more {
      display: flex;
      color: $white !important;
      text-decoration: none;

      &:after {
        width: 26px;
        height: 26px;
        margin-left: 10px;
        content: "";
        background-image: url("img/link-more-icon.svg");
        filter: invert(92%) sepia(66%) saturate(3671%) hue-rotate(177deg) brightness(105%) contrast(113%);
      }
      &:hover {
        text-decoration: underline;
        &:after {
          transform: scale(0.9);
        }
      }
    }
  }
  &.tertiary {
    background-color: $tertiary;
    color: $primary;
  }
  &.three-steps {
    color: $white;
    max-width: 384px;

    p {
      font-size: $font-size-md;
    }
    span {
      position: relative;
      display: inline-block;
      background-color: $dark-grey;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 38px;
      img {
        width: 150%;
        position: absolute;
        left: -7px;
        top: -48px;
      }
      img.sign {
        top: -58px;
        width: 130%;
      }
      @include media-breakpoint-up(md) {
        width: 161px;
        height: 161px;
        img {
          width: auto;
          position: absolute;
          left: 0;
          top: -60px;
        }
        img.sign {
          width: auto;
          top: -55px;
        }
        img.get {
          top: -52px;
        }
      }
    }
  }
  &.contact {
    padding-top: 15px;
    padding-bottom: 10px !important;
    @include media-breakpoint-up(md) {
      padding-top: 30px;
      padding-bottom: 20px !important;
    }
    a {
      color: $white !important;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    ul.list-contact {
      li {
        &:before {
          @include media-breakpoint-only(xs) {
            margin-right: 5px;
          }
        }
        span {
          @include media-breakpoint-up(sm) {
            margin-left: 34px;
          }
        }
      }
    }
    img {
      right: -14px;
      max-height: 218px;
      bottom: 0;
      @include media-breakpoint-up(sm) {
        max-height: 248px;
        right: -50px;
        bottom: 0;
      }
      @media screen and (max-width: 320px) {
        max-height: 182px;
      }
      @media screen and (max-width: 376px) {
        right: -50px;
      }
    }
  }
  &.story {
    h2 {
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
    .story-img {
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      min-height: 350px;
      @include media-breakpoint-up(lg) {
        min-height: 550px;
      }
    }
  }
}
.article-view {
  .display-flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    & > [class*="col-"] {
      display: flex;
      flex-direction: column;
      .card-container {
        height: 100%;
        background: $tertiary;
        border-radius: $border-radius-sm;
        position: relative;
      }
    }
  }
}

// OLD styling for tip van Leendert
.panel-tip {
  background: $primary url("/global/images/leendert.png") no-repeat;
  background-size: auto 100%;
  margin: 30px 0;
  min-height: 300px;
  position: relative;
  display: flex;
  border-radius: 16px;
  border-radius: 16px;

  .arrow-container {
    background: url("/global/images/bg_banner_tip.svg");
    background-repeat: no-repeat;
    min-height: 300px;
    background-size: auto 100%;
    background-position: left;
    flex: auto;
    width: 44%;
    @include media-breakpoint-only(md) {
      background-position: 30%;
    }
    @include media-breakpoint-only(xs) {
      display: none;
    }
  }
  .text-container {
    padding: 20px;
    padding-left: 0;
    color: #fff;
    background: $primary;
    flex: auto;
    width: 40%;
    font-size: 13px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    @include media-breakpoint-up(md) {
      padding-left: 30px;
    }
    @include media-breakpoint-only(xs) {
      padding-top: 72px;
    }

    h3 {
      color: #ffffff;
      margin-top: 10px;
      @include media-breakpoint-only(xs) {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
  .ribbon {
    position: absolute;
    top: 15px;
    left: -10px;
    color: #fff;
    z-index: 1;
    width: auto;
    height: 40px;
    line-height: 36px;
    background: $secondary;
    &::after {
      content: "";
      font-size: 14px;
      position: absolute;
      z-index: -1;
      top: 0;
      right: -40px;
      border-top: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid $secondary;
    }
    &::before {
      content: "";
      font-size: 14px;
      position: absolute;
      border-style: solid;
      border-color: darken($secondary, 15%) transparent transparent transparent;
      top: 40px;
      left: 0px;
      border-width: 11px 0px 23px 10px;
    }
    span {
      margin-left: 10px;
      margin-top: 6px;
      font-size: 13px;
      display: inline-block;
      line-height: 1;
    }
  }
}
