.table-responsive {
  overflow-x: auto;
}
table.calc-example {
  font-size: $font-size-sm;
  width: 100%;
  border: 1px solid #d4e4ec;
  background-color: $white;
  border-radius: $border-radius-table;
  border-collapse: separate;
  border-spacing: 0;
  > :not(caption) > * > * {
    border-bottom-color: #d4e4ec !important;
  }
  thead {
    background-color: #E9F7FF;
    th {
      vertical-align: top;
    }
  }
  tr:last-child {
    td {
      white-space: nowrap;
      border-bottom: 0;
    }
  }
}
