.btn {
	border-radius: $border-radius-btn;
}
.btn-secondary {
	padding: 14px 30px;
	color: $white !important;
	text-decoration: none;
	font-size: $font-size-md;
	font-weight: 700;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none !important;
		color: $white;
	}
}
.btn-outline-secondary {
	color: $secondary !important;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none !important;
		background: $secondary;
		color: $white !important;
		i {
			color: $white !important;
		}
	}
	&.orange {
		background: $secondary !important;
		color: $white !important;
	}
}
.btn-icon {
	border-color: $secondary;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	&.mijn-directa {
		position: relative;
		&:after {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -45%);
			width: 16px;
			height: 20px;
			content: url(img/mijndirecta.svg);
			filter: invert(53%) sepia(71%) saturate(3394%) hue-rotate(341deg)
				brightness(101%) contrast(97%);
		}
	}
	&:hover {
		background-color: $secondary;
		&:after {
			filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(116deg)
				brightness(109%) contrast(109%);
		}
	}
}
.btn-link {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

article {
	.btn-outline-secondary {
		font-weight: 700;
		padding: 14px 30px;
	}
}
