#accordion .autocompleteWidget {
	overflow: visible;
	position: relative;
}
.autocompleteWidget {
	.suggesting-input {
		position: absolute;
		top: 11px;
		left: 16px;
		color: #ccc;
		z-index: 0 !important;
		border: 0;
	}
	.form-control {
		position: relative;
		background: transparent !important;
		z-index: 1 !important;
	}
	ul {
		display: none !important;
	}
}
.group-container {
	display: flex !important;
	align-items: center !important;
	#AddressHouseNumber {
		max-width: 100px;
	}
	.autocompletebox {
		&.houseNumberSupplement {
			min-width: 60px;
			margin-left: 2px;
		}
	}
	.houseNumberSupplement {
		&.suggesting-input {
			display: none !important;
		}
	}
}

.tipsy:not(.error) {
	margin-top: -3px;
	margin-left: 8px;
}

.tipsy-inner,
.errortip-inner {
	padding: 10px;
	background-color: $red;
	color: $white;
	max-width: 250px;
	text-align: left;
	font-size: $font-size-sm;
	border-radius: $border-radius-table;
	z-index: 2;
}
.tipsy-west,
.errortip-west {
	&:before {
		position: absolute;
		left: -6px;
		top: 50%;
		margin-top: -3px;
	}
}

.validicon {
	&:before {
		@include fa-icon();
		content: $fa-var-check;
		display: block;
		color: $green;
		font-size: $font-size-md;
		margin-left: 10px;
	}
}

.tipsy-east,
.errortip-east {
	&.tipsy:not(.error) {
		margin: 41px 0 0 24px;
	}

	&:before {
		position: absolute;
		right: 6px;
		top: -6px;
	}
}

.errortip-inner {
	color: $red !important;
}

.error.tipsy {
	display: inline-block;
	overflow: hidden;
	color: transparent !important;
	max-height: 28px;
	position: relative;
	top: 10px;
	&:before {
		width: 26px;
		height: 26px;
		border-radius: 50%;
		border: 1px solid $red;
		background-color: $red;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		font-family: FontAwesome;
		font-size: 1rem;
		cursor: pointer;
		content: "\f12a";
		margin-left: 10px;
	}
	&:hover {
		text-decoration: none !important;
		&:before {
			text-decoration: none !important;
			background-color: $red;
			color: $white !important;
		}
	}
}

.radiolist-buttons {
	.error.tipsy {
		top:16px;
	}
}

.apply-wizard-nav {
	justify-content: space-between;
	border-bottom: 0 !important;

	> li {
		margin: 0;
		counter-increment: apply-wizard-nav-counter;
		display: flex;
		position: relative;
		flex: 1;
		justify-content: center;

		.line {
			position: absolute;
			border-top: 4px solid #b9c7d2;
			width: calc(150% - 45px);
			left: 50%;
			top: 20px;
			z-index: 1;
			@include media-breakpoint-up(md) {
				width: calc(150% - 100px);
			}
		}

		> a {
			cursor: default;
			position: relative;
			text-decoration: none;
			color: $primary;
			font-weight: 600;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			&:before {
				content: counter(apply-wizard-nav-counter);
				background-color: $white;
				font-size: $font-size-lg;
				color: #b9c7d2;
				border-radius: 50%;
				border: 2px solid #b9c7d2;
				width: 44px;
				height: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 10px;
				z-index: 2;
			}
			.apply-wizard-nav-steplabel {
				display: none;
				@include media-breakpoint-up(md) {
					display: block;
					width: 100%;
					text-align: center;
				}
			}
			&.active {
				&:before {
					color: $green;
					border: 2px solid $green;
				}
			}
		}
		&.completed {
			.line {
				border-top: 4px solid $green;
			}
			> a {
				&:before {
					font-family: FontAwesome;
					content: "\f00c";
					color: $white;
					background-color: $green;
					border-color: $green;
				}
			}
		}
		&:first-child {
			justify-content: flex-start;
			> .line {
				width: calc(150% - 45px);
				left: 45px;
			}
		}
		&:last-child {
			justify-content: flex-end;
			> .line {
				display: none;
			}
		}
	}
}

#accordion {
	.usps {
		margin-bottom: 1rem;
		small {
			margin-right: 20px;
			&:first-child::before {
				content: "\f005";
				font-family: FontAwesome;
				color: $secondary;
				padding-right: 5px;
			}
			&:nth-child(2)::before {
				content: "\f153";
				font-family: FontAwesome;
				color: $secondary;
				padding-right: 5px;
			}
		}
	}
}

.calculator-options {
	align-items: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	label {
		width: 127px !important;
		height: 138px;
		margin-right: 12px;
		margin-bottom: 12px;
		border: 1px solid $light-grey;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		border-radius: 6px;
		cursor: pointer;
		@include media-breakpoint-up(sm) {
			width: 152px;
			height: 138px;
			margin-right: 20px;
		}
		input {
			display: none;
		}
		span {
			color: $primary;
			font-weight: 400;
			display: block;
			font-size: 16px !important;
			margin-bottom: 10px;
			max-width: 111px;
			text-align: center;
		}
		&:hover {
			transform: scale(0.95);
		}
	}
	.futureWithdrawals-yes,
	.futureWithdrawals-no {
		display: none;
	}
	label.radio-inline {
		&.futureWithdrawals {
			min-width: 100%;
			padding: 25px;
			height: auto !important;
			display: inline-block !important;
			@include media-breakpoint-up(xl) {
				min-width: 46%;
			}
			@media only screen and (max-device-width: 375px) {
				padding: 15px;
				padding-right: 5px;
			}
			.list-container {
				margin-left: 44px;
				@media only screen and (max-device-width: 375px) {
					margin-left: 34px;
				}
			}
			h5 {
				font-weight: 600;
				display: flex;
				width: 100%;
				@media only screen and (max-device-width: 375px) {
					font-size: $font-size-md;
					flex-wrap: wrap;
				}
				&:before {
					content: " ";
					border-radius: 50%;
					border: 1px solid $light-grey;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					margin-right: 20px;
					margin-bottom: -7px;
					@media only screen and (max-device-width: 375px) {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
			&.selected-radio {
				background-color: rgba($secondary, 0.1);
				border-color: $secondary;
				h5 {
					&:before {
						border-color: $secondary;
						background-color: $secondary;
						font-family: FontAwesome;
						content: "\f00c";
						color: #fff;
						font-size: $font-size-sm;
					}
				}
			}
		}
		span {
			color: $primary;
			font-weight: 400;
			display: block;
			font-size: 16px !important;
			margin-bottom: 10px;
			max-width: 111px;
			text-align: center;
		}
		&:hover {
			transform: scale(0.95);
		}
	}
	.futureWithdrawals-yes,
	.futureWithdrawals-no {
		display: none;
	}
	label.radio-inline {
		&.futureWithdrawals {
			min-width: 100%;
			padding: 25px;
			height: auto !important;
			display: inline-block !important;
			@include media-breakpoint-up(xl) {
				min-width: 46%;
			}
			@media only screen and (max-device-width: 375px) {
				padding: 15px;
				padding-right: 5px;
			}
			.list-container {
				margin-left: 44px;
				@media only screen and (max-device-width: 375px) {
					margin-left: 34px;
				}
			}
			h5 {
				font-weight: 600;
				display: flex;
				width: 100%;
				@media only screen and (max-device-width: 375px) {
					font-size: $font-size-md;
					flex-wrap: wrap;
				}
				&:before {
					content: " ";
					border-radius: 50%;
					border: 1px solid $light-grey;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					margin-right: 20px;
					margin-bottom: -7px;
					@media only screen and (max-device-width: 375px) {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
			&.selected-radio {
				background-color: rgba($secondary, 0.1);
				border-color: $secondary;
				h5 {
					&:before {
						border-color: $secondary;
						background-color: $secondary;
						font-family: FontAwesome;
						content: "\f00c";
						color: #fff;
						font-size: $font-size-sm;
					}
				}
			}
		}
	}
}

#SpendingPurpose-error,
#futureWithdrawals-error {
	display: none;
}
.calculator-options .radio-inline {
	&.six-options-with-icons-01,
	&.six-options-with-icons-02,
	&.six-options-with-icons-03,
	&.six-options-with-icons-04,
	&.six-options-with-icons-05,
	&.six-options-with-icons-07,
	&.six-options-with-icons-08,
	&.six-options-with-icons-12,
	&.six-options-with-icons-11 {
		background-repeat: no-repeat;
		background-position: center 30px;
		&.selected-radio {
			background-color: rgba($secondary, 0.1);
			border-color: $secondary;
		}
	}
	&.six-options-with-icons-08 {
		background-image: url("img/car.svg");
	}
	&.six-options-with-icons-12 {
		background-image: url("img/camper.svg");
	}
	&.six-options-with-icons-02 {
		background-image: url("img/transfer.svg");
	}
	&.six-options-with-icons-03 {
		background-image: url("img/house.svg");
		background-size: 49px;
	}
	&.six-options-with-icons-04 {
		background-image: url("img/garden.svg");
	}
	&.six-options-with-icons-05 {
		background-image: url("img/laptop.svg");
	}
	&.six-options-with-icons-07 {
		background-position: center 60px;
		background-image: url("img/different.svg");
	}
	&.six-options-with-icons-11 {
		background-image: url("img/house-sustainability.svg");
		background-size: 56px;
		background-position-y: 23px;
	}
}

.accept {
	display: flex;
	align-items: center;

	input {
		margin-right: 20px;
		margin-top: -10px;
		&:before {
			content: "";
			background: #fff;
			border: 2px solid $light-grey;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			padding: 2px;
		}
		&:checked {
			&:before {
				content: "\f00c";
				font-family: "FontAwesome";
				background: $green;
				color: #fff;
				font-size: 12px;
			}
		}
	}
	a {
		text-decoration: underline !important;
		&:hover {
			text-decoration: none !important;
		}
	}
	.error {
		&.tipsy {
			top: 0px !important;
		}
	}
	p {
		margin: 0 !important;
	}
}

.apply {
	h3 {
		font-size: 28px !important;
		font-weight: 700;
	}
}

.calculation-result-mobile {
	width: 100vw;
	height: 75px;
	background: $primary;
	position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;
	.panel {
		margin-bottom: 0;
		border-color: $primary;
		.panel-heading {
			background: $primary;
			color: #fff;
			border-bottom: 1px solid #1c538d;
			border-radius: 0;
			padding: 0;
			a {
				display: flex;
				align-content: center;
				align-items: center;
				text-decoration: none;
				padding: 10px 5px;
				&:active {
					background: lighten($primary, 5%);
				}
				div {
					margin-right: 12px;
					p {
						color: #fff;
						font-size: 12px;
						margin-bottom: 0;
						@include media-breakpoint-up(sm) {
							font-size: 16px;
						}
						@media only screen and (max-width: 320px) {
							font-size: 10px;
						}

						em {
							font-style: normal;
						}
					}
					span {
						color: #fff;
						font-size: 16px;
						@media only screen and (max-width: 320px) {
							font-size: 10px;
						}
					}
				}
				span.collapse-button {
					background: url(img/collapse-button-apply.svg) no-repeat;
					background-size: 38px 38px;
					width: 38px;
					height: 38px;
					transition-duration: 0.5s;
					transition-property: transform;
					margin-right: 10px;
					&.open {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.panel-collapse {
		.panel-body {
			background: $primary;
			color: #fff;
			h3 {
				color: #fff;
				margin: 0;
			}
			ul {
				list-style: none;
				margin: 28px 0 28px 0;
				padding: 0;
				font-size: 15px;
				opacity: 0;
				li {
					border-bottom: 1px solid #1c538d;
					line-height: 40px;
					font-weight: bold;
					em {
						font-style: normal;
					}
					span {
						font-weight: normal;
						display: inline-block;
						width: 60vw;
						font-size: 17px;
					}
					&:last-child {
						border: 0;
					}
				}
			}
		}
	}
	&.visible {
		visibility: visible !important;
	}
	&.invisible {
		visibility: hidden !important;
	}
	.card {
		&.tertiary {
			margin: 15px;
		}
	}
}

.apply-calculator-aflossing-options {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 92%;
	& > input {
		position: absolute;
		visibility: hidden;

		+ label {
			display: inline-block;
			background-color: $white;
			padding: 6px 12px;
			border-radius: 4px;
			margin: 0;
			flex-grow: 1;
			margin-bottom: 2px;
			border: 1px solid $light-grey;
			text-align: center;
			margin-right: 10px;
			cursor: pointer;
			&:last-child {
				margin-right: 0;
			}

			span {
				margin-bottom: -6px;
				display: block;
				color: $primary;
			}
			small {
				font-size: 11px;
				color: $primary;
			}
		}
		&[disabled] {
			+ label {
				background-color: rgba($white, 0.2);
				span {
					color: $white;
				}
				small {
					color: $white;
				}
			}
		}

		&:checked:not([disabled]) {
			+ label {
				background-color: $secondary;
				border-color: $dark-grey;
				color: $white;
				box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.48);
				font-weight: 600;
				span {
					color: $white;
				}
				small {
					color: $white;
				}
			}
		}
	}
}

body.apply {
	nav {
		display: none;
	}
}

//QCP-6058
#BorrowerGender_HR, #BorrowerGender_MW {
	position: unset;
	clip: unset;
	pointer-events: unset;
	& + .btn-outline-secondary {
		text-transform: capitalize;
		border: 0;
		padding: 0;
		margin-top: 0;
		margin-right: 1rem;
		&:hover {
			background-color: transparent !important;
			color: $primary !important;
		}
		
	}
	&:checked + .btn-outline-secondary {
		background-color: transparent;
		background-image: none;
		color: $primary !important;
		&:hover {
			background-color: transparent !important;
			color: $primary !important;
		}
	}
}
