.homepage-calculator {
	display: flex;
	height: 100%;
	align-items: flex-end;
	padding-bottom: 20px;
	@include media-breakpoint-up(lg) {
		align-items: center;
	}
	a.how-much {
		font-size: $font-size-md;
		display: inline-block;
		text-align: center;
		margin-top: 15px;
		width: 100%;
		color: $primary;
		&:hover {
			text-decoration: none;
		}
	}
	label {
		color: $black;
		font-size: $font-size-md;
		font-weight: 400;
	}
	.btn {
		font-size: $font-size-lg;
	}
	.range {
		width: 100%;
		.loanAmount-input {
			border: 1px solid #cfd5dc;
			border-radius: 6px;
			padding: 10px 3px;
			padding-bottom: 8px;
			font-size: 21px;
			width: 100%;
			font-size: 24px;
			font-weight: 700;
			color: $primary;
			margin-bottom: 20px;
			text-align: center;
			&.error {
				border-color: red;
				font-size: 14px;
				height: 51px;
			}
			&::placeholder {
				color: $secondary;
				font-size: 14px !important;
			}
		}
		.range-label {
			display: flex;
			color: $primary;
			justify-content: space-between;
		}
		.range__slider {
			width: 100%;
		}
		.range__value {
			width: 35%;
			margin-left: 45px;
			text-align: center;
		}
	}

	.range__slider label {
		margin-bottom: 5px;
	}

	.range__slider [type="range"] {
		width: 100%;
		-webkit-appearance: none;
		height: 8px;
		border-radius: 5px;
		background: #6a7b8d;
		outline: none;
		padding: 0;
		margin: 0;
	}

	/* custom thumb */
	.range__slider [type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: $secondary url("img/handle.svg");
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		box-shadow: 0px 1px 3px 0px #00000040;
	}

	.range__slider [type="range"]::-moz-range-thumb {
		appearance: none;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: $secondary url("img/handle.svg");
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		box-shadow: 0px 1px 3px 0px #00000040;
	}

	/* remove border */
	input::-moz-focus-inner,
	input::-moz-focus-outer {
		border: 0;
	}
}

.col {
	&.calculator {
		@include media-breakpoint-up(sm) {
			margin: 0;
			margin-right: 10px;
		}
	}
}

.calculator-container {
	.input-group-text {
		background-color: $white;
		color: $primary;
		font-size: $font-size-lg;
		font-weight: 600;
	}
	h4 {
		font-size: $font-size-lg;
		display: flex;
		align-items: center;
		&.error {
			color: $red !important;
		}
	}
	h2 {
		display: flex;
		align-items: center;
	}
	.calculator-options {
		align-items: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		label {
			width: 127px !important;
			height: 138px;
			margin-right: 12px;
			margin-bottom: 12px;
			border: 1px solid $light-grey;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			border-radius: 6px;
			cursor: pointer;
			@include media-breakpoint-up(sm) {
				width: 152px;
				height: 138px;
				margin-right: 20px;
			}
			input {
				display: none;
			}
			span {
				color: $primary;
				font-weight: 400;
				display: block;
				font-size: 16px !important;
				margin-bottom: 10px;
				max-width: 111px;
				text-align: center;
			}
			&:hover {
				transform: scale(0.95);
			}
		}
		.futureWithdrawals-yes,
		.futureWithdrawals-no {
			display: none;
		}
	}

	#SpendingPurpose-error,
	#futureWithdrawals-error {
		display: none;
	}
	.calculator-options .radio-inline {
		&.six-options-with-icons-01,
		&.six-options-with-icons-02,
		&.six-options-with-icons-03,
		&.six-options-with-icons-04,
		&.six-options-with-icons-05,
		&.six-options-with-icons-07,
		&.six-options-with-icons-08,
		&.six-options-with-icons-12,
		&.six-options-with-icons-11 {
			background-repeat: no-repeat;
			background-position: center 30px;
			&.selected-radio {
				background-color: rgba($secondary, 0.1);
				border-color: $secondary;
			}
		}
		&.six-options-with-icons-08 {
			background-image: url("img/car.svg");
		}
		&.six-options-with-icons-12 {
			background-image: url("img/camper.svg");
		}
		&.six-options-with-icons-02 {
			background-image: url("img/transfer.svg");
		}
		&.six-options-with-icons-03 {
			background-image: url("img/house.svg");
			background-size: 49px;
		}
		&.six-options-with-icons-04 {
			background-image: url("img/garden.svg");
		}
		&.six-options-with-icons-05 {
			background-image: url("img/laptop.svg");
		}
		&.six-options-with-icons-07 {
			background-position: center 60px;
			background-image: url("img/different.svg");
		}
		&.six-options-with-icons-11 {
			background-image: url("img/house-sustainability.svg");
			background-size: 56px;
			background-position-y:23px;
		}
	}
	.btn-secondary {
		color: $white !important;
		&:hover {
			text-decoration: none !important;
		}
	}
}

.calculator {
	label {
		color: $white;
		display: inline;
	}
	.calculator-form {
		text-align: center;
	}

	.calculator-notavailable {
		display: none;
		position: relative;
	}
}

// new
.calculator {
	background-color: $primary;
	color: $white;
	border-radius: 8px;
	border: 0;
	padding: 15px;
	@include media-breakpoint-up(md) {
		padding: 30px;
	}

	h3 {
		color: $white !important;
		font-size: $font-size-lg;
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.tooltip-btn {
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
		border: 1px solid $white;
		color: $white !important;
		cursor: pointer;
		font-size: $font-size-sm;
		display: inline-flex !important;
		margin-left: 4px;
		text-decoration: none !important;
		&:hover {
			text-decoration: none !important;
			background-color: $white;
			color: $primary !important;
		}
	}

	.calculator-summary {
		margin-top: 20px;
		table {
			width: 100%;
			td {
				padding: 6px 0;
				vertical-align: top;
				&:last-child {
					text-align: right;
				}
			}
		}
		&.disabled {
			opacity: 0.3;
		}
	}
	.calculator-aflossing-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > input {
			position: absolute;
			visibility: hidden;

			+ label {
				display: inline-block;
				background-color: $white;
				padding: 6px 12px;
				border-radius: 4px;
				margin: 0;
				flex-grow: 1;
				margin-bottom: 2px;
				text-align: center;
				margin-right: 10px;
				cursor: pointer;
				&:last-child {
					margin-right: 0;
				}

				span {
					margin-bottom: -6px;
					display: block;
					color: $primary;
				}
				small {
					font-size: 11px;
					color: $primary;
				}
			}
			&[disabled] {
				+ label {
					background-color: rgba($white, 0.2);
					span {
						color: $white;
					}
					small {
						color: $white;
					}
				}
			}

			&:checked:not([disabled]) {
				+ label {
					background-color: $secondary;
					border-color: $dark-grey;
					color: $white;
					box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.48);
					font-weight: 600;
					span {
						color: $white;
					}
					small {
						color: $white;
					}
				}
			}
		}
	}
}

.calculator-summary {
	a {
		color: $white !important;
		text-decoration: underline !important;
		&:hover {
			color: $white !important;
			text-decoration: none !important;
		}
	}
}

.noUi-target {
	border: 0 !important;
	box-shadow: none !important;
}

.notification-withdrawals {
	display: none;
}

.min-max {
	display: flex;
	justify-content: space-between;
	font-size: $font-size-sm !important;
	opacity: 0.8;
}

.with-check {
	margin-bottom: 1rem;
	&:before {
		font-family: FontAwesome;
		content: "\f058";
		font-size: 20px;
		color: $green;
		margin-right: 10px;
	}
}
