

$grid-columns:                24 !default;
$spacer: 1rem !default;

$grid-gutter-width:           0.7rem !default;


$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.575 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-md: $font-size-base * 1.13 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$h1-font-size: $font-size-base * 2.2 !default;
$h2-font-size: $font-size-base * 2.0 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$primary: #043d7a !default;
$secondary: #fb6632 !default;
$tertiary: #F1f7FA !default;
$light-grey: #cfd5dc !default;
$dark-blue: #102134 !default;
$medium-grey: #6A7B8D !default;
$dark-grey: #3f5671 !default;

$green: #00c70e !default;

$border-radius-btn: 60px;
$border-radius-sm: 16px;
$border-radius-xs: 6px;
$border-radius-table: 4px;

$position-values: (
  0: 0,
  5: 5%,
  10: 10%,
  50: 50%,
  100: 100%,
) !default;

$tooltip-font-size:                 $font-size-sm;
$tooltip-max-width:                 300px;
$tooltip-opacity:                   1;
$tooltip-bg:                        $dark-grey;
$tooltip-padding-y:                 $spacer;
$tooltip-padding-x:                 $spacer;
$tooltip-margin:                    0;

$tooltip-arrow-width:               .8rem;
$tooltip-arrow-height:              .4rem;
$tooltip-arrow-color:               $tooltip-bg;

$form-check-input-checked-bg-color:       $secondary;
$form-check-input-checked-border-color:   #FFF;
