/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local(""), url("/global/fonts/nunito/nunito-v20-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/global/fonts/nunito/nunito-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local(""), url("/global/fonts/nunito/nunito-v20-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/global/fonts/nunito/nunito-v20-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local(""), url("/global/fonts/nunito/nunito-v20-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/global/fonts/nunito/nunito-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: $dark-blue;
}

h1 {
  font-weight: 700;
}
h2 {
  font-weight: 700;
}
h3,
h4 {
  font-weight: 600;
  strong {
    font-weight: 700;
  }
}
.h2-like-title {
  font-weight: 700;
  font-size: calc(1.325rem + 0.9vw);
}
.h4-like-title {
  font-weight: 600;
  font-size: 1.3rem;
}
.primary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}
small {
  color: #6a7b8d;
}

a.link-more,
button.link-more {
  display: flex;
  color: $primary !important;
  text-decoration: none;
  &:after {
    width: 26px;
    height: 26px;
    display: block;
    content: "";
    margin-left: 10px;
    background-image: url("img/link-more-icon.svg");
    filter: invert(48%) sepia(9%) saturate(6269%) hue-rotate(337deg) brightness(102%) contrast(97%);
  }
  &:hover {
    text-decoration: underline;
    &:after {
      transform: scale(0.9);
    }
  }
}
a {
  text-decoration: none;
  color: $secondary;
  &:hover {
    color: $secondary;
    text-decoration: underline;
  }
}
.content {
  .card.white {
    h1 {
      margin-bottom: 1.6rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary;
    }
    a {
      text-decoration: none;
      color: $secondary;
      &:hover {
        text-decoration: underline;
      }
    }
    .panel-faq-category,
    ul.links-404,
    .other-questions-list {
      a {
        text-decoration: none;
        color: $primary;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .card.tertiary {
    a {
      text-decoration: none;
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  article {
    p {
      margin-bottom: 1.8rem;
    }
    h2 {
      font-size: $font-size-base * 1.4;
    }
    h3 {
      font-size: $font-size-base * 1.25;
    }
    hr {
      border: 0;
      border-top: 2px dashed darken(#d4e4ec, 20%);
      margin: 48px 0;
      height: 2px;
      background: none;
      color: none;
    }
  }
}

h2 {
  i.fa-chevron-right {
    font-size: $font-size-md;
    margin: 0 10px;
  }
}
