.jumbotron {
  background: no-repeat top right;
  background-size: auto 327px;
  min-height: 540px;
  background-position: -62px -22px;
  background-image: url('/global/images/jumbotron-round_smaller.jpg') !important;
  background-color: $primary;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(md) {
    background-image: url('/global/images/jumbotron-round.png') !important;
  }
  .container {
    height: 100%;
  }
  @include media-breakpoint-only(sm) {
    background-size: auto 550px;
    background-position: -82px 0px;
    min-height: 550px;
  }
  @include media-breakpoint-only(md) {
    background-size: auto 550px;
    background-position: 0px 0px;
    min-height: 550px;
  }
  @include media-breakpoint-only(lg) {
    background: no-repeat center right;
    background-size: auto 650px;
    background-position: 100px 0px;
    min-height: 650px;
    background-color: $primary;
  }
  @include media-breakpoint-only(xl) {
    background: no-repeat center right;
    background-size: auto 650px;
    background-position: 150px 0px;
    min-height: 650px;
    background-color: $primary;
  }
  @include media-breakpoint-only(xxl) {
    background: no-repeat center right;
    background-size: auto 650px;
    background-position: 70% 0px;
    min-height: 650px;
    background-color: $primary;
  }
}
