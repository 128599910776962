.breadcrumb {
  margin-top: -2rem;
  margin-left: 0.3rem;
  li {
    a {
      font-size: $font-size-sm;
      color: $medium-grey;
    }
  }
}
