footer {
	.accordion {
		.accordion-item {
			background-color: $primary;
			color: $white;
			h2 {
				color: $white;
			}
			.accordion-button {
				background-color: $primary;
				color: $white;
				&:after {
					width: 12px;
					height: 7px;
					background-size: 12px;
					background-image: url(img/down-arrow.svg);
				}
			}
		}
		.accordion-body {
			a {
				font-size: $font-size-sm;
			}
		}
	}
}
.content-accordion {
	.accordion-item {
		border: 0;
		.accordion-button {
			font-size: $font-size-lg;
			font-weight: 600;
			background-color: $tertiary;
			color: $primary;
			border: 1px solid #d4e4ec;
			border-radius: 8px;
			&:after {
				background-image: url(img/collapse-arrow.svg);
			}
		}
	}
}
