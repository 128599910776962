body,
html {
  overflow-x: hidden;
}
body {
  #aanvraag-header-btn {
    display: none;
    @include media-breakpoint-up(xl) {
      display: inline-block;
    }
  }
  #content-header-btn {
    display: none;
    @include media-breakpoint-up(xl) {
      display: inline-block;
    }
  }
  #small-content-header-btn {
    display: inline-block;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  #small-contact-header-btn {
    display: inline-block;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  #small-aanvraag-header-btn {
    display: inline-block;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  &.apply {
    #aanvraag-header-btn {
      display: none;
      @include media-breakpoint-up(xl) {
        display: inline-block;
      }
    }
    #small-aanvraag-header-btn {
      display: inline-block;
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
    #content-header-btn {
      display: none;
    }
    #small-content-header-btn {
      display: none;
    }
  }
}
.alternating-tertiary {
  background-color: $tertiary;
  border-top: 1px solid #d4e4ec;
  border-bottom: 1px solid #d4e4ec;
}
.alternating-primary {
  background-color: $primary;
  color: $white;
  .steps {
    h2 {
      font-weight: 600;
    }
  }
}
.alternating-light {
  border-top: 1px solid #d4e4ec;
  border-bottom: 1px solid #d4e4ec;
  background-color: #f1f7fa;
  color: $primary;
}

.d-flex {
  .table-responsive {
    width: 100%;
  }
}

.hidden,
.invisible {
  display: none !important;
}

.loading-step-overlay {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  opacity: 1;
  transition: 2s all ease-in-out;

  .holder {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 70%;
    transform: translateX(-50%) translateY(-50%);
    @include media-breakpoint-up(md) {
      min-width: auto;
    }

    .bouncing-loader {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      & > div {
        width: 1rem;
        height: 1rem;
        margin: 3rem 0.2rem;
        background: $secondary;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;
      }
      & > div:nth-child(2) {
        animation-delay: 0.2s;
      }
      & > div:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  &.fade-out {
    opacity: 0;
  }
}

@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}
#qacc-bar {
  background-color: rgba(0, 0, 0, 0.8);
}

main {
  @include media-breakpoint-only(xs) {
    margin-top: 70px;
  }
}

@include media-breakpoint-only(xs) {
  .scrollable {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-left: -30px;
    > .col {
      min-width: 400px;
    }
  }
}
.content {
  img {
    border-radius: 16px;
  }
}

body #wm-livechat {
  bottom: 20px !important;
  right: -10px !important;
}
