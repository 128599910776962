.quote {
	padding: 40px;
	background: $tertiary;
	border-radius: 16px;
	margin-bottom: 1rem;
	text-align: center;
	background-image: url(img/quote-2.svg);
	background-size: 7%;
	background-repeat: no-repeat;
	background-position: bottom 20px right 40px;
  @include media-breakpoint-up(sm) {
    padding: 50px;
    background-position: bottom 30px right 50px;
  }
	.quote-header {
		background-image: url(img/quote-1.svg);
		background-size: 8%;
		background-repeat: no-repeat;
		background-position: bottom left;
		strong {
			font-size: 24px;
			font-weight: 600;
			color: $primary;
		}
		span {
			display: block;
			font-weight: 600;
		}
	}
	p {
		font-weight: 600;
		font-size: 22px;
	}
}
