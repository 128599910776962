.pager {
  display: flex;
  justify-content: flex-end;
  .page-item.active .page-link {
    background-color: $tertiary;
    color: $primary;
  }
  .page-item {
    span {
      display: inline-block;
      margin: 0 10px;
    }
  }

  ul {
    li {
      margin: 0 10px;
      &:before {
        display: none;
      }
    }
  }
}
