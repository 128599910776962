footer {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		a {
			color: $white;
			text-decoration: none;
			display: block;
			margin-bottom: 0.5em;
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
	}
	.bottom {
		color: $black;
		a {
			color: $black;
			display: inline;
			text-decoration: none;
			&:hover {
				color: $black;
				text-decoration: underline;
			}
			&:after {
				content: "|";
				margin: 0 10px;
			}
			&:nth-child(6) {
				&:after {
					content: "";
				}
			}
		}
		a.img-link {
			display: inline-block;
			&:after {
				content: "";
			}
		}
	}
	.footer-menu-small {
		a {
      &:after {
				content: "|";
				margin: 0 10px;
			}
		}
    a.img-link {
      margin-top:15px;
			display: inline-block;
			&:after {
				content: "";
			}
		}
	}
}
