nav {
	&.navbar {
		font-weight: 600;
		@include media-breakpoint-up(xxl) {
			font-size: $font-size-md;
		}

		.navbar-brand {
			color: $primary;
			padding-left: 5px;
			img {
				width: 100px;
				@include media-breakpoint-up(sm) {
					width: 130px;
				}
				@media screen and (max-width: 320px) {
					width: 94px;
				}
			}
		}
		.navbar-toggler {
			font-size: 1.5rem;
			color: $primary;

			span {
				display: block;
				font-size: $font-size-xs;
			}
			&:focus {
				box-shadow: none;
			}
		}
		.navbar-collapse {
			justify-content: end;
			li {
				&.nav-item {
					margin-left: 0.5rem;
					@include media-breakpoint-only(xl) {
						margin-left: 0;
					}
					a.nav-link {
						color: $primary;
						&:hover {
							color: $secondary;
						}
						&:focus {
							outline: none !important;
						}
						&.dropdown-toggle {
							&:after {
								font-family: FontAwesome;
								font-size: $font-size-xs;
								content: "\f078";
								margin-left: 5px;
								vertical-align: middle;
								margin-top: -2px;
								color: $secondary;
								border: 0;
							}
						}
					}
					.btn {
						font-size: $font-size-sm;
						margin-top: 3px;
						font-weight: 700;
					}
					i {
						color: $secondary;
						margin-right: 0.5rem;
					}
				}
			}
			&.collapse {
				&.show {
					@include media-breakpoint-only(xs) {
						box-shadow: 1px 10px 19px -11px rgba(0, 0, 0, 0.75);
						-webkit-box-shadow: 1px 10px 19px -11px rgba(0, 0, 0, 0.75);
						-moz-box-shadow: 1px 10px 19px -11px rgba(0, 0, 0, 0.75);
						padding: 0 0 30px 0;
					}
				}
			}
		}
	}
}

aside {
	nav {
		li {
			margin-top: 1rem;
			width: 100%;
			display: flex;
			&:before {
				font-family: FontAwesome;
				content: "\f054";
				color: $secondary;
				margin-right: 7px;
			}
			a {
				color: $white;
				text-decoration: none;
				&:hover {
					color: $white;
					text-decoration: underline;
				}
			}
			&:first-child {
				margin-top: 0;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.mijn-directa-dropdown {
		left: -158px !important;
	}
}
