header {
  @include media-breakpoint-only(xs) {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
  }
  .btn-outline-secondary {
    &.small {
      margin-top: 5px;
      padding: 4px 13px;
      font-size: $font-size-sm;
      line-height: auto;
      max-height: 30px;
      border-radius: 90px;
      color: $secondary;
    }
  }
  .maintenance-banner {
    padding: 15px;
    box-shadow: 5px 5px 12px -3px rgba(0, 0, 0, 0.2);
    background: darken($tertiary, 2%);
    width: 100%;
    display: flex;
    align-items: center;
    color: $primary;
    font-size: $font-size-md;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    .container {
      &:before {
        content: "\f071";
				font-family: FontAwesome;
				color: $danger;
      }
    }
  }
}
