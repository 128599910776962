.divider-block {
	padding: 20px;
	background: $primary;
	border-radius: 16px;
	margin-bottom: 1rem;
	color: $white !important;
  @include media-breakpoint-up(sm) {
    padding: 30px;
		padding-bottom:10px;
  }
	h1,h2,h3,h4,h5,h6 {
		color: $white !important;
	}
}
