$fa-font-path: "../fonts/font-awesome";

@import "../../node_modules/font-awesome/scss/_variables";
@import "../../node_modules/font-awesome/scss/_mixins";
@import "../../node_modules/font-awesome/scss/_path";
@import "../../node_modules/font-awesome/scss/_core";
//@import "../../node_modules/font-awesome/scss/_larger";
//@import "../../node_modules/font-awesome/scss/_fixed-width";
//@import "../../node_modules/font-awesome/scss/_list";
//@import "../../node_modules/font-awesome/scss/_bordered-pulled";
//@import "../../node_modules/font-awesome/scss/_animated";
//@import "../../node_modules/font-awesome/scss/_rotated-flipped";
//@import "../../node_modules/font-awesome/scss/_stacked";
@import "../../node_modules/font-awesome/scss/_icons";

.fa-check {
  color:$green;
}
.fa-times {
  color: $danger;
} 
