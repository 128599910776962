.form-select {
	color: $medium-grey;
	background-image: url("img/select-arrow.svg");
}
legend {
	font-size: $font-size-lg;
	font-weight: 600;
	color: $primary;
	margin-top: 1.5rem !important;
}
label {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	font-size: $font-size-md;
	color: $primary;
	font-weight: 600;
	&.btn {
		display: inline-block;
		font-weight: 400;
		border: $input-border-width solid $input-border-color;
		appearance: none;
		@include border-radius($input-border-radius, 0);

		@include box-shadow($input-box-shadow);
		@include transition($input-transition);
	}
	&.btn-outline-secondary {
		color: $primary !important;
		&:hover {
			color: #fff !important;
		}
	}
}

#CybotCookiebotDialog label {
	display: inline-block;
	margin-top: inherit;
	font-size: inherit;

	font-weight: normal;
}
.btn-check:checked + .btn-outline-secondary {
	color: $white !important;
}
.input-wrapper {
	display: flex;
	align-items: center;
	&:before {
		content: "€";
		font-size: $font-size-lg;
		color: $primary;
		font-weight: 700;
		margin-left: 20px;
		display: inline-block;
		position: absolute;

		pointer-events: none;
	}
}
.form-control {
	padding: 10px 15px;
	color: $medium-grey;
	border-color: #cfd5dc;
	font-weight: 400;
	&.amount,
	&.amount-noslider {
		font-size: $font-size-lg;
		color: $primary;
		font-weight: 700;
		padding-left: 45px;
	}
	&.error {
		background-color: rgba($red, 0.2);
		border-color: $red;
	}
}
.form-group {
	&.hide {
		display: none;
	}
}
.apply {
	.full,
	.half {
		width: calc(100% - 40px);
		display: inline-block;
	}
	.sixth {
		width: 18.6666%;
		display: inline-block;
		@include media-breakpoint-up(md) {
			width: 10%;
		}
	}
	.third {
		width: 33%;
		display: inline-block;
		@include media-breakpoint-up(md) {
			width: 16.6666%;
		}
	}
	.header:not(:first-child) {
		padding-top: 3rem;
		margin-top: 3rem;
		border-top: 2px dashed #d4e4ec;
	}
	.help-block {
		display: block; // account for any element using help-block
		margin-top: 5px;
		margin-bottom: 10px;
		color: lighten($dark-blue, 25%); // lighten the text some for contrast
	}
}

.input-wrapper {
	input:disabled {
		background-color: $light-grey;
	}
	&.spinner:after {
		font-family: FontAwesome;
		content: "\f021";
		color: $secondary;
		position: absolute;
		right: 23px;
		animation: 2s linear 0s normal none infinite spin;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
