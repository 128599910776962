.tooltip-btn {
  min-width: 28px;
  min-height: 28px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: $font-size-md;
  cursor: pointer;
  font-weight: 700;
  color: $primary !important;
  @media only screen and (max-device-width: 375px) {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }
  &:hover {
    text-decoration: none !important;
    background-color: $primary;
    color: $white !important;
  }
  &.error {
    border-color: $red;
    color: $red !important;
    &:hover {
      text-decoration: none !important;
      background-color: $red;
      color: $white !important;
    }
  }
}
