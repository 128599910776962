.afm {
  background: url(img/afm.svg) no-repeat center center;
  text-indent: -99999px;
  background-size: 260px;
  height: 70px;
  @include media-breakpoint-up(sm) {
    background-size: 380px;
    height: 100px;
  }
}

.apply {
  .apply-afm-container {
    @include media-breakpoint-only(xs) {
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .afm {
    @include media-breakpoint-down(md) {
      background-position-x: right;
    }
  }
  .apply-logo {
    @include media-breakpoint-down(md) {
      display: block !important;
      width: 90px;
      height: 18px;
      position: absolute;
      top: 26px;
      left: 15px;
    }
    @include media-breakpoint-up(md) {
      display: block !important;
      position: absolute;
      top: 35px;
    }
    @include media-breakpoint-only(sm) {
      top: 40px;
    }
  }
}
